import {
  Directive,
  Input,
  OnChanges,
  Optional,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { hasMatchedPermissionByName } from '../utils/hasMatchedPermission';
import { Permission } from '../utils/permissions';
import { PlanFormService } from '../../../features/plans/services/plan/plan-form.service';

export interface AllPermissions {
  system?: Permission[];
  plan?: Permission[];
}

@Directive({
  standalone: true,
  selector: '[appAllowedPermissionNames]',
})
export class AllowedPermissionsDirective implements OnChanges {
  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<any>,
    private auth: AuthService,
    @Optional() private planFormService: PlanFormService,
  ) {}

  private _appAllowedPermissionNames: AllPermissions = { system: [], plan: [] };

  get appAllowedPermissionNames() {
    return this._appAllowedPermissionNames;
  }

  @Input() set appAllowedPermissionNames(value: AllPermissions) {
    this._appAllowedPermissionNames = value;
  }

  ngOnChanges() {
    let allowed = hasMatchedPermissionByName(
      this._appAllowedPermissionNames.system || [],
      this.auth.getUserAssignedPermissions(),
    );

    if (this.planFormService && !allowed) {
      allowed = hasMatchedPermissionByName(
        this._appAllowedPermissionNames.plan || [],
        this.planFormService.formGroup.value.assignedPermissions || [],
      );
    }

    allowed ? this.view.createEmbeddedView(this.template) : this.view.clear();
  }
}
