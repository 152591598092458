import { Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { firstValueFrom, skip } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { hasMatchedPermissionByValue } from '../utils/hasMatchedPermission';
import { ERRORS_MODULE_ROUTING_SEGMENTS } from '../../../routing';
import { Permission } from '../utils/permissions';
import { PlanFormService } from '../../../features/plans/services/plan/plan-form.service';

@Injectable()
export class PermissionsGuard {
  constructor(
    private auth: AuthService,
    private router: Router,
    @Optional() private planFormService: PlanFormService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    let allowedSystemPermissions = (route.data['allowedSystemPermissions'] ||
      []) as Permission[];

    let allowedPlanPermissions = (route.data['allowedPlanPermissions'] ||
      []) as Permission[];

    if (!this.auth.user$.value) {
      await firstValueFrom(this.auth.user$.pipe(skip(1)));
    }

    let userPermissions = this.auth.getUserAssignedPermissions();

    if (
      allowedSystemPermissions.length === 0 &&
      allowedPlanPermissions.length === 0
    ) {
      return true;
    }

    let allowed = hasMatchedPermissionByValue(
      allowedSystemPermissions,
      userPermissions,
    );

    if (this.planFormService && !allowed) {
      allowed = this.checkPlanPermissions(allowedPlanPermissions);
    }

    if (allowed) return true;

    return this.handleNavigation(route);
  }

  private checkPlanPermissions(allowedPlanPermissions: Permission[]) {
    let userPlanPermissions =
      this.planFormService.formGroup.value.assignedPermissions || [];

    return hasMatchedPermissionByValue(
      allowedPlanPermissions,
      userPlanPermissions,
    );
  }

  private handleNavigation(route: ActivatedRouteSnapshot) {
    const hasFallbackRoute = !!route.data['fallbackRoute'];

    return hasFallbackRoute
      ? this.router.navigate([route.data['fallbackRoute'](route)])
      : this.router.navigate([ERRORS_MODULE_ROUTING_SEGMENTS.NO_PERMISSIONS], {
          state: {
            isFromRedirect: true,
          },
        });
  }
}
