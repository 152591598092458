import { EventEmitter, Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { MonitoringService } from '@epa-services/monitoring/monitoring.service';
import { BehaviorSubject, filter, tap } from 'rxjs';
import { UserService } from '../user/user.service';
import { User } from './types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: BehaviorSubject<User | undefined> = new BehaviorSubject<
    User | undefined
  >(undefined);
  msalRedirectEvent = new EventEmitter<boolean>();

  constructor(
    private msal: MsalService,
    private msalBroadcast: MsalBroadcastService,
    private user: UserService,
    private monitoringService: MonitoringService,
  ) {
    this.msal.instance.initialize().then();

    this.msalBroadcast.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        tap(() => this.setActiveAccount()),
        tap(() => this.msalRedirectEvent.emit(true)),
      )
      .subscribe();
  }

  async checkLogin() {
    await this.msal.instance.initialize();
    await this.msal.instance.handleRedirectPromise();
  }

  async logout() {
    await this.msal.instance.logoutRedirect();
    this.monitoringService.clearUserId();
  }

  async setActiveAccount() {
    this.user
      .getUserData()
      .pipe(
        tap((userData) => {
          this.user$.next(userData);
          if (userData.email) {
            this.monitoringService.setUserContext(userData.email);
          }
        }),
      )
      .subscribe();
  }

  getUserAssignedPermissions() {
    return this.user$.value?.assignedPermissions || [];
  }
}
